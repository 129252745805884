<template>

  <AuditBase
      title="昵称"
      :auditTypes=auditTypes
      :handle-moles=handleMoles
      defaultHandleMole="IGNORE">
  </AuditBase>

</template>

<script>
import AuditBase from "./audit-base"

export default {

  data() {
    return {
      auditTypes: [1],
      handleMoles: [
        {
          handleMode: 'IGNORE',
          desc: '忽略'
        },
        {
          handleMode: 'REMOVE_NICK',
          desc: '处罚'
        }
      ]
    }

  },
  components: {
    AuditBase
  },
  methods: {}
}
</script>

<style scoped lang="scss">
</style>

